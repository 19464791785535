import * as React from "react"
import { Helmet } from "react-helmet"

import IDALayout from "../../components/layouts/layout-ida"
import Seo from "../../components/seo"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import CalloutIcon1 from "../../images/icons/icon-callout-ida-efficacy.png"
import akbcares from "../../images/AkebiaCares-logo.png"
import PrescribeIcon from "../../images/icons/icon-callout-ida-dosing.png"

const IdaConnectThankYouPage = ({ location }) => {
    return (
        <>
            <IDALayout
                location={location}
                locationHostName={location.hostname}
                jobCode="PP-AUR-US-1408 (v4.0)"
                jobCodeDate="09/24"
                references={[
                    <>
                        Webster AC, Nagler EV, Morton RL, Masson P. Chronic
                        kidney disease. <em>Lancet</em>.
                        2017;389(10075):1238-1252.
                    </>,
                    <>
                        AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA:
                        Akebia Therapeutics, Inc.; 2021.
                    </>,
                ]}
            >
                <Helmet>
                    <html lang="en" />
                    <link
                        rel="canonical"
                        href="https://www.auryxiahcp.com/iron-deficiency-anemia/thank-you"
                    />
                    <body id="ida-hcp-connect-thank-you" className="ida" />
                </Helmet>

                <Seo
                    title="Latest News Sign-up"
                    description="Talk to a sales representative, order samples, or receive additional information about AURYXIA. Read Important Safety Information and Prescribing Information on this page."
                />

                <section id="hero" className="flood-ida col-8 center">
                    <div className="flex-row">
                        <div>
                            <h1>THANK YOU</h1>
                            <p className="center_text">
                                You have successfully signed up to request
                                samples or connect with a sales representative.
                            </p>
                        </div>
                    </div>
                </section>

                <EopCalloutCards
                    siteAudience="ida"
                    callOneClass={"highlight"}
                    callOneLink={"/iron-deficiency-anemia/efficacy/"}
                    callOneContent={
                        <>
                            <img
                                src={CalloutIcon1}
                                alt=""
                                width="98"
                                height="98"
                            />
                            <h3>See what AURYXIA can do for your patients</h3>
                            <p>
                                AURYXIA increased Hb and iron parameters (TSAT
                                and ferritin) without IV iron or ESA use in a
                                16-week pivotal trial.<sup>1-2</sup>
                            </p>
                            <button className="cta ida">
                                <span>EXAMINE EFFICACY</span>
                            </button>
                            <p className="footnote center">
                                IV iron use and ESA use were not allowed per
                                trial design and exclusion criteria.<sup>1</sup>
                            </p>
                        </>
                    }
                    callTwoClass={"default"}
                    callTwoLink={"/iron-deficiency-anemia/dosing/"}
                    callTwoContent={
                        <>
                            <img
                                src={PrescribeIcon}
                                alt=""
                                width="98"
                                height="98"
                            />
                            <h3>Ready to prescribe?</h3>
                            <p>
                                AURYXIA provides the convenience of at-home
                                treatment, so it&#x2019;s important to learn
                                about dosing and administration.<sup>2</sup>
                            </p>
                            <button className="cta ida">
                                <span>GET DOSING INFORMATION</span>
                            </button>
                        </>
                    }
                />

                <section id="crosslink-slab" className="akb akb_moa_section">
                    <div
                        className="flex-row"
                        data-sal="zoom-in"
                        data-sal-duration="600"
                        data-sal-delay="200"
                        data-sal-ease="ease-out-expo"
                    >
                        <div>
                            <img src={akbcares} alt="AkebiaCares" height="81" />
                            <h3>
                                Your partner in helping patients access the
                                medication they need
                            </h3>
                            <p className="boldTxt">
                                Connect with a personal Case Manager today!
                            </p>
                            <p className="med_width">
                                AkebiaCares personal Case Managers are standing
                                by live to answer your call and help your
                                patients find coverage.
                            </p>
                            <ul className="info_ul">
                                <li>
                                    <a href="tel:18334253242">
                                        1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)
                                    </a>
                                </li>
                                <li>Monday&nbsp;-&nbsp;Friday</li>
                                <li className="hours_li">
                                    8<span>AM</span>&nbsp;–&nbsp;8
                                    <span>PM</span>&nbsp;EST
                                </li>
                            </ul>
                            <a
                                href="https://www.akebiacareshcp.com/"
                                className="cta akb"
                            >
                                <span>FIND SUPPORT OPTIONS</span>
                            </a>
                        </div>
                    </div>
                </section>
            </IDALayout>
        </>
    )
}

export default IdaConnectThankYouPage
